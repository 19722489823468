import axios, { isAxiosError } from 'axios';
import {
  CheckupRequestInfo,
  ClinicWithPetsResponse,
  CreateCheckupRequestForPetResult,
  CreateCheckupWithCompletedCheckupIdResponse,
  ItemUploadSignedUrl
} from '@companion-professional/dctypes';
import {
  CHECKUP_REQUEST_URL,
  GENERATE_UPLOAD_URL,
  CREATE_CHECKUP_FOR_CLINIC_PET_URL,
  GET_CLINIC_BY_SLUG_URL,
  NEW_CHECKUP_FROM_EXISTING_CHECKUP
} from './config';

type CheckupCompleteResponse = {
  checkupComplete: boolean;
};

// This file contains all the API HTTP requests for the application.

// getCheckupRequest is a function that fetches a single checkup request.
export const getCheckupRequest = async (
  checkupRequestId: string
): Promise<CheckupRequestInfo | CheckupCompleteResponse> => {
  try {
    const response = await axios.get<CheckupRequestInfo>(`${CHECKUP_REQUEST_URL}/${checkupRequestId}`);
    return response.data;
  } catch (e) {
    if (isAxiosError(e) && e?.response?.status === 409) {
      // A 409 status code means that the checkup is complete.
      return {
        checkupComplete: true
      };
    }
    throw e;
  }
};

// getItemUploadUrl is a function that fetches a signed URL for uploading a video to MUX
export const getItemUploadUrl = async (itemId: string): Promise<ItemUploadSignedUrl> => {
  const response = await axios.post<ItemUploadSignedUrl>(`${GENERATE_UPLOAD_URL}/${itemId}`);
  return response.data;
};

// getClinicBySlug is a function that fetches a clinic by its slug.  It can also take an optional petIds parameter that
// if provided, returns pet information (e.g. names).
export const getClinicBySlug = async (
  clinicSlug: string,
  clinicPetIds: string | null
): Promise<ClinicWithPetsResponse> => {
  const queryParams: Record<string, unknown> = {};
  if (clinicPetIds) {
    queryParams['pet_ids'] = clinicPetIds;
  }
  const response = await axios.get<ClinicWithPetsResponse>(`${GET_CLINIC_BY_SLUG_URL}/${clinicSlug}`, {
    params: queryParams
  });
  return response.data;
};

// createCheckupForClinicPetId is a function that creates a checkup for a clinic (clinicId) and that clinic's pet ID
// (clinicPetId).
export const createCheckupForClinicPetId = async (clinicId: string, clinicPetId: string): Promise<string> => {
  const response = await axios.post<CreateCheckupRequestForPetResult>(CREATE_CHECKUP_FOR_CLINIC_PET_URL, {
    clinic_id: clinicId,
    clinic_pet_id: clinicPetId
  });
  return response.data.checkup_id;
};

// createCheckupUsingExistingCheckupRequest is a function that creates a checkup using an existing checkup request.
// Returns the checkup ID that can in turn be used to navigate to the new checkup.
export const createCheckupUsingExistingCheckup = async (checkupId: string): Promise<string> => {
  const response = await axios.post<CreateCheckupWithCompletedCheckupIdResponse>(
    `${NEW_CHECKUP_FROM_EXISTING_CHECKUP}/${checkupId}`
  );
  return response.data.checkup_id;
};
