import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { VideoCaptureUploader } from '../../components/VideoCaptureUploader';
import { CaptureWithVideoInstructions } from '../../components/CaptureWithVideoInstructions';
import { useCameraPreviewEnabled, useSetCameraPreviewEnabled } from '../../state/camera';
import { NeedHelpContactSupport } from '../../components/NeedHelpContactSupport';
import { trackEvent } from '../../lib/matomo';

// The following are used for displaying the instructions video (encoded in Mux).  If we want to change the video, we
// will need to update these values.  The video can be uploaded by using Mux's web interface located here:
// https://dashboard.mux.com/organizations/4od8hp/environments/n3bo4i/video/assets#create  All the default options
// should be fine (encoding = smart, max resolution = 4k, and generate captions set to disabled).  After the video is
// uploaded, the playback ID can be found in the video's settings.  The poster image is just this url (with the
// playback ID): https://image.mux.com/{playback_id}/thumbnail.png?time=15&width=480
// https://dashboard.mux.com/organizations/4od8hp/environments/n3bo4i/video/assets/imtljanva2cDdIMhhr2scfEm01XVysbLlJF4eKzyOcDM/build
const instructionVideoMuxPlaybackId = 'rVedvh6i02QCTJw2wRxZin6VgWkWZLBnLTd6fMQHfx7E';
const instructionVideoPoster =
  'https://image.mux.com/rVedvh6i02QCTJw2wRxZin6VgWkWZLBnLTd6fMQHfx7E/thumbnail.png?width=480&time=22';

interface CheckupProps {
  // checkupRequest is the checkup request that is being fulfilled.
  checkupRequest: CheckupRequestInfo;
}

// Checkup is the heart of the checkup capture flow.  It is responsible for allowing the user to add a video/photo to
// the checkup.  It also has a form area to capture any additional information about the checkup.
export const Checkup: FunctionComponent<CheckupProps> = ({ checkupRequest }) => {
  const navigate = useNavigate();
  const setCameraPreviewEnabled = useSetCameraPreviewEnabled();
  const cameraPreviewEnabled = useCameraPreviewEnabled();

  // This is here to support only one file per request.
  const [oneFile, setOneFile] = useState<File | null>(null);

  return (
    <div className="flex w-full flex-1 flex-col justify-between pb-4">
      <div className="flex w-full flex-1 flex-col items-center">
        {oneFile ? (
          <VideoCaptureUploader
            itemId={checkupRequest?.requests?.[0]?.item_id}
            file={oneFile}
            onReset={() => {
              Sentry.addBreadcrumb({
                category: 'checkup_flow',
                message: 'Video capture reset'
              });
              setOneFile(null);
            }}
            onUploadComplete={() => {
              trackEvent('checkup', 'checkup_flow_change', 'upload_video_complete');
              Sentry.addBreadcrumb({
                category: 'checkup_flow',
                message: 'Video upload complete'
              });

              // Once the video is uploaded, we can consider this checkup complete so navigate to the complete page.
              navigate('../complete');
            }}
            onUploadStart={() => {
              trackEvent('checkup', 'checkup_flow_change', 'upload_video_start');
              Sentry.addBreadcrumb({
                category: 'checkup_flow',
                message: 'Video upload started'
              });
            }}
          />
        ) : (
          <CaptureWithVideoInstructions
            instructionVideoMuxPlaybackId={instructionVideoMuxPlaybackId}
            instructionVideoPoster={instructionVideoPoster}
            onVideoSelected={(file, stats) => {
              if (stats) {
                Sentry.addBreadcrumb({
                  category: 'checkup_flow',
                  message: `Video captured: ${stats.duration} seconds, ${stats.size} bytes`,
                  data: {
                    type: stats.type,
                    duration: stats.duration,
                    size: stats.size,
                    width: stats.width,
                    height: stats.height
                  }
                });
              } else {
                Sentry.addBreadcrumb({
                  category: 'checkup_flow',
                  message: 'Video captured (no stats)'
                });
              }

              setOneFile(file);
              setCameraPreviewEnabled(false);
            }}
          />
        )}
      </div>
      {!cameraPreviewEnabled ? <NeedHelpContactSupport /> : null}
    </div>
  );
};
