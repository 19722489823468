import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { getCheckupRequest } from '../lib/api';

// useCheckupRequest is a hook that fetches a single checkup request. In addition to all the usual query return values
// (with the data in checkupRequest), it also returns a checkupComplete boolean that indicates whether the checkup is
// complete.
export const useCheckupRequest = (checkupRequestId: string) => {
  const [checkupComplete, setCheckupComplete] = useState<boolean>(false);

  const {
    isPending,
    error,
    data: checkupRequest
  } = useQuery<CheckupRequestInfo | null>({
    queryKey: ['checkupRequest', checkupRequestId],
    queryFn: async () => {
      const checkup = await getCheckupRequest(checkupRequestId);
      if ('checkupComplete' in checkup) {
        setCheckupComplete(checkup.checkupComplete);
        return null;
      }
      return checkup;
    },

    // Setting the stale time to 30 seconds so that we're not constantly fetching the same data from the server.  We
    // might want to increase this value depending on the user's behavior (e.g. if they hang out on the landing page
    // for over 30 seconds, and we expect that the data will most likely stay the same, we might want to increase the
    // time).
    staleTime: 1000 * 30 // 30 seconds
  });

  return {
    isPending,
    error,
    checkupRequest,
    checkupComplete
  };
};
