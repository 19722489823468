import React, { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon } from 'lucide-react';
import { Button, QueryLoaderWrapper } from '@companion-professional/components';
import { useSetHeaderBarTitle } from '../../state/headerBar';
import { NeedHelpContactSupport } from '../../components/NeedHelpContactSupport';
import { trackEvent } from '../../lib/matomo';
import { useCreateCheckupUsingExistingCheckup } from '../../hooks/useCreateCheckupUsingExistingCheckup';

interface CheckupCompleteProps {
  checkupId: string;
  message?: string;
}

// CheckupComplete is the page that is shown to the Pet Parent when they have completed the checkup.
export const CheckupComplete: FunctionComponent<CheckupCompleteProps> = ({
  checkupId,
  message = 'You can now close this tab. The results will be sent to your veterinarian.'
}) => {
  const navigate = useNavigate();
  const { isPending, error, createCheckupWithExistingCheckup } = useCreateCheckupUsingExistingCheckup();
  const setHeaderBarTitle = useSetHeaderBarTitle();

  useEffect(() => {
    trackEvent('checkup', 'checkup_flow_change', 'checkup_complete');
    setHeaderBarTitle('Checkup Complete');
  }, []);

  return (
    <div className="mb-4 flex flex-1 flex-col justify-between">
      <div className="flex flex-1 flex-col justify-center">
        <div className="space-y-4 p-4">
          <div className="flex flex-row items-center justify-center gap-2">
            <CheckCircleIcon className="h-8 w-8 text-primary" />
            <div className="text-xl font-bold text-primary md:text-2xl">Checkup Complete!</div>
          </div>
          <div className="text-center text-gray-700">{message}</div>
          <NeedHelpContactSupport className="text-center" />
        </div>
      </div>
      <div className="flex flex-row justify-center">
        <QueryLoaderWrapper isPending={isPending} error={error} size="small">
          <Button
            variant="secondary"
            type="button"
            onClick={async () => {
              trackEvent('checkup', 'checkup_flow_change', 'create_another_checkup');
              const newCheckupId = await createCheckupWithExistingCheckup(checkupId);
              if (newCheckupId) {
                navigate(`/${newCheckupId}`, { replace: true });
                // Refresh the page to show the new checkup.
                window.location.reload();
              }
            }}
          >
            Upload another video
          </Button>
        </QueryLoaderWrapper>
      </div>
    </div>
  );
};
