import { useMutation } from '@tanstack/react-query';
import { createCheckupUsingExistingCheckup } from '../lib/api';

// useCreateCheckupUsingExistingCheckup is a hook that creates a new checkup using an existing checkup request.
export const useCreateCheckupUsingExistingCheckup = () => {
  const {
    mutateAsync: createCheckupWithExistingCheckup,
    isPending,
    error
  } = useMutation({
    mutationFn: async (existingCheckupId: string) => createCheckupUsingExistingCheckup(existingCheckupId)
  });

  return {
    createCheckupWithExistingCheckup,
    isPending,
    error
  };
};
